<template>
  <div class="noOverflow">
    <div id="fensterMagic">
      <div ref="head" class="head headTrans"
           :style="{ backgroundImage: 'url(' + image + ')' }"></div>
      <!--img ref="head" class="head head2" :src="image2" alt="head"-->
      <div class="container justify-content-center align-items-center">
        <div v-if="state === 0" class="row">
          <div class="col-12">
            <h1 class="txt colorfull">Wie transparent bist du wirklich?</h1>
          </div>
        </div>
        <!--  EBENE 1 -->
        <div ref="FrameEbene" class="row ebene1 headTrans">
          <span class="reading wordshead" ref="text1"></span>
          <span class="reading wordshead" ref="text2"></span>
          <span class="reading wordshead" ref="text3"></span>
          <span class="reading wordshead" ref="text4"></span>
          <span class="reading wordshead" ref="text5"></span>
          <span class="reading wordshead" ref="text6"></span>
        </div>
        <div ref="FrameEbene" class="row ebene11">
          <span class="reading wordshead" ref="text11"></span>
          <span class="reading wordshead" ref="text22"></span>
          <span class="reading wordshead" ref="text33"></span>
          <span class="reading wordshead" ref="text44"></span>
          <span class="reading wordshead" ref="text55"></span>
          <span class="reading wordshead" ref="text66"></span>
        </div>
        <!--  EBENE 2 -->
        <div class="row  ebene2">
          <div class="col col-2 col-lg-3 kat">
            <i class="bi bi-shop farbe1"></i>
            <h5 class="">Ernährung</h5>
            <p class="beschreibung miniText">Rezepte, Restaurants, Gerichte</p>
          </div>
          <div class="col-8 col-lg-6"></div>
          <div class="col-2 col-lg-3 kat">
            <i class="bi bi-suit-heart farbe2"></i>
            <h5 class="">Interessen</h5>
            <p class="beschreibung miniText">Spezifisches Interesse, Personen</p>
          </div>
          <div class="col-2 col-lg-3 kat">
            <i class="bi bi-tv farbe3"></i>
            <h5 class="">Unterhaltung</h5>
            <p class="beschreibung miniText">Filme und Serien, Musik, Spiele</p>
          </div>
          <div class="col-8 col-lg-6"><h4 id="katInfo" class="colorfull">
            Um die enormen Mengen an Daten verarbeiten zu
            können, wird jede deiner Googlesuchen kategorisiert.
          </h4>
            <h4 id="katInfo2" class="colorfull">
              Doch von dir existieren noch viel mehr Daten als nur dein Suchverlauf.
            </h4></div>
          <div class="col-2 col-lg-3 kat">
            <i class="bi bi-briefcase farbe4"></i>
            <h5 class="">Reisen</h5>
            <p class="beschreibung miniText">Urlaubsplanung, Navigation, ÖPNV </p>
          </div>
          <div class="col-2 col-lg-3 kat">
            <i class="bi bi-lightbulb farbe5"></i>
            <h5 class="">Wissen</h5>
            <p class="beschreibung miniText">Nachrichten, Fragen, Tutorials</p>
          </div>
          <div class="col-8 col-lg-6"></div>
          <div class="col-2 col-lg-3 kat">
            <i class="bi bi-basket farbe6"></i>
            <h5 class="">Shopping</h5>
            <p class="beschreibung miniText">Onlineshopping, Suche nach Geschäften</p>
          </div>
        </div>
        <!--  EBENE 3 -->
        <div class="row ebene3">
          <div class="col col-4 kat fade" ref="fade">
            <i class="bi bi-activity grey"></i>
            <p class="reading grey">Fitness Tracker</p>
          </div>
          <div class="col col-4 kat fade" ref="fade">
            <i class="bi bi-cart3 grey"></i>
            <p class="reading grey">Online Shops</p>
          </div>
          <div class="col col-4 kat fade" ref="fade">
            <i class="bi bi-geo-alt grey"></i>
            <p class="reading grey">Standortverlauf</p>
          </div>
          <div class="col col-4 kat fade" ref="fade">
            <i class="bi bi-instagram grey"></i>
            <p class="reading grey">Soziale Netzwerke</p>
          </div>
          <div class="col col-4 kat" id="googlesuche">
            <i class="bi bi-google"></i>
            <h4 class="">Suchverlauf</h4>
          </div>
          <div class="col col-4 kat fade">
            <i class="bi bi-envelope-open grey"></i>
            <p class="reading grey">e-mail-Verlauf</p>
          </div>
          <div class="col col-4 kat fade">
            <i class="bi bi-messenger grey"></i>
            <p class="reading grey">Chatverlauf</p>
          </div>
          <div class="col col-4 kat fade">
            <i class="bi bi-youtube grey"></i>
            <p class="reading grey">Streaming Portale</p>
          </div>
          <div class="col col-4 kat fade">
            <i class="bi bi-heart grey"></i>
            <p class="reading grey">Dating Portale</p>
          </div>
        </div>
        <div ref="animationBack" class="row ebene31"></div>
        <!--  EBENE 4 -->
        <div class="row ebene4">
          <div class="col-12">
            <h1 class="colorfull margin">Was wäre wenn?</h1>
            <h4 class=" unterpunkt1">Jemand all deine Daten miteinander kombiniert.</h4>
            <h4 class=" unterpunkt2">Genau weiß, dass du dich z.B. für {{ InteressenMost5[0] }},
              {{ InteressenMost5[1] }}, {{ InteressenMost5[2] }} aber auch für
              {{ GesundheitMost5[1] }} interessierst.</h4>
            <h4 class=" unterpunkt3">Und dabei noch deine Intimsten Suchanfragen kennt.</h4>
          </div>
        </div>
        <!--  EBENE 5 -->
        <div class="row  ebene5">
          <div class="col col-12 kat">
            <p class="reading unterpunkt4 colorfull">dein Kaufverhalten zu beeinflussen.
              <i @click="goQuelle" class="bi bi-info-circle biKlein"></i>
            </p>
          </div>
          <div class="col col-4 kat">
            <p class="reading unterpunkt5 colorfull">dich als Mensch zu bewerten.
              <i @click="goQuelle" class="bi bi-info-circle biKlein"></i>
            </p>
          </div>
          <div class="col-4"><h4 id="5Info" class="">
            All diese Informationen könnten beispielsweise dazu benutzt werden, um ...
          </h4></div>
          <div class="col col-4 kat">
            <p class="reading unterpunkt6 colorfull">dir gewisse Weltanschauungenzu vermitteln.
              <i @click="goQuelle" class="bi bi-info-circle biKlein"></i></p>
          </div>
          <div class="col col- kat">
            <p class="reading unterpunkt7 colorfull">dich für eigene Zwecke zu manipulieren.
              <i @click="goQuelle" class="bi bi-info-circle biKlein"></i></p>
          </div>
          <div class="col col-6 kat">
            <p class="reading unterpunkt8 colorfull">deine politische Meinung zu beeinflussen.
              <i @click="goQuelle" class="bi bi-info-circle biKlein"></i></p>
          </div>
        </div>
        <!--  EBENE 6 -->
        <div class="row ebene6">
          <div class="col-12">
            <h1 class="colorfull margin">Was kannst du tun?</h1>
            <p class="reading einstellung1"><strong>1.</strong>
              Es gibt neben Google, auch noch andere Suchmaschienen
              wie z.B. Ecosia oder DuckDuckGo. Sie speichern weniger informationen über dich. </p>
            <p class="reading einstellung2"><strong>2.</strong>
              Du kannst deine Google-Datenschutzeinstellungen <a class="link" href="https://myaccount.google.com/data-and-privacy" target="_blank">hier</a>
              ändern.
              Dann werden die Suchanfragen garnicht erst gespeichert oder automatisch gelöscht.</p>
            <p class="reading einstellung3"><strong>3.</strong>
              Außerdem solltest du auf Webseiten nur essenzielle Cookies akzeptieren.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable */

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import lottie2 from 'lottie-web/build/player/lottie';
import connectJSON from '../animations/lotti/connect.json';

export default {
  name: 'magicMoment',
  props: {
    InteressenMost5: Array,
    NahrungMost5: Array,
    UnterhaltungMost5: Array,
    ReisenMost5: Array,
    WissenMost5: Array,
    ShoppingMost5: Array,
    GesundheitMost5: Array,
  },
  data() {
    return {
      state: 0,
      breite: window.innerWidth,
      hohe: window.innerHeight,
      // eslint-disable-next-line global-require
      image: require('@/assets/media/img.png'),
      most70: ['Curry', 'Gemüse', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger'],
      text1: '',
      colors: ['#05de8b', '#07a2e0', '#5447f1',
        '#a1027e', '#f8143c', '#ff7a11', 'rgb(255,255,255)'],
      i: 0,
      durationShort: 6,
      durationLong: 12,
      done: false,
    };
  },
  mounted() {

    lottie2.loadAnimation({
      container: this.$refs.animationBack,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: connectJSON,
    });

    gsap.registerPlugin(ScrollTrigger);

    let timeline = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: '#fensterMagic',
        start: 'top top', // top center bottom  20px 80%
        end: '+=' + this.hohe, // * 1.1, // end after scrolling 500px beyond the start
        markers: false,
        scrub: true,
        duration:1000,
        pin: '#fensterMagic',
        //onLeave: () => this.$emit('finished', 'fertig'),
        //toggleActions: "restart pause reverse pause"
      }
    });
    timeline.totalDuration(1000)

    timeline.addLabel('start')

      // Start with Text
      .fromTo('.txt', {
        opacity: 1
      }, {
        duration: this.durationLong,
        opacity: 0
      })

      // Show Head
      .fromTo('.head', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })

      // Show Ebene 1
      .call(this.stateManage)
      .call(this.typeWriter)
      .to('.ebene1', {
        opacity: 1,
        display: 'flex',
        duration: this.durationLong,
      })
      .to('.ebene11', {
        opacity: 1,
        duration: 0.1,
        display: 'flex',
      })
      .call(this.colorizeBack)
      .call(this.colorize)
      .to('.ebene11', {
        opacity: 0,
        display: 'none',
        duration: this.durationLong
      })

      // Show Ebene 2
      .fromTo('.ebene2', {
        opacity: 0,
        display: 'none',
        scale: 10
      }, {
        duration: this.durationShort,
        opacity: 1,
        display: 'flex',
        scale: 1
      })
      // Just to make a break
      .to('.ebene2', {
        duration: this.durationShort,
        opacity: 1
      })
      .fromTo('.beschreibung', {
        opacity: 0,
        scale: 0.6
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      // Just to make a break
      .to('.ebene2', {
        duration: this.durationLong,
        opacity: 1,
        display: 'flex',
      })

      // leave Ebene 2
      .to('.ebene1', {
        //  colors: ['#05de8b', '#07a2e0', '#5447f1','#a1027e', '#f8143c', '#ff7a11',
        backgroundImage: 'linear-gradient(170deg, #05de8b, #07a2e0, #5447f1, #a1027e, #ff7a11, #ff7a11)',
        duration: this.durationLong,
        //scale: 0.6
      })
      .to('.wordshead', {
        opacity: 0,
        duration: this.durationShort
      })
      .to('.headTrans', {
        duration: this.durationLong,
        scale: 0
      })
      // Infos über Katigorisierung
      .to('#katInfo', {
        duration: this.durationShort,
        opacity: 1
      })
      // break
      .to('#katInfo', {
        duration: this.durationLong,
        opacity: 1
      })

      .to('#katInfo2', {
        duration: this.durationLong,
        opacity: 1
      })
      // Just to make a break
      .to('.ebene1', {
        duration: this.durationLong,
        opacity: 0,
        display: 'none',
      })
      .to('.ebene2', {
        scale: 0.3,
        opacity: 0,
        display: 'none',
        duration: this.durationShort
      })

      .to('.ebene3', {
        display: 'flex',
      })
      // Show Ebene 3
      .fromTo('#googlesuche', {
        opacity: 0,
        scale: 10
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      // Just to make a break
      .to('#googlesuche', {
        duration: this.durationLong,
        opacity: 1
      })

      // Show Lotti Background Animation
      .fromTo('.ebene31', {
        opacity: 0,
        display: 'none',
        scale: 0.2
      }, {
        duration: this.durationLong,
        opacity: 0.7,
        display: 'flex',
        scale: 1
      })
      .call(this.lottiPlay)
      .fromTo('.fade', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      // Just to make a break
      .to('.ebene1', {
        duration: this.durationLong,
        opacity: 0
      })
      // Play animation back
      .call(this.lottiPlayBack)
      .to('.ebene3', {
        opacity: 0,
        display: 'none',
        duration: this.durationLong
      })

      // Show Ebene 4
      .fromTo('.ebene4', {
        opacity: 0,
        display: 'none',
        scale: 0.2
      }, {
        duration: this.durationLong,
        opacity: 1,
        display: 'flex',
        scale: 1
      })
      // Just to make a break
      .to('.ebene4', {
        duration: this.durationShort,
        opacity: 1
      })
      .to('.unterpunkt1', {
        duration: this.durationLong,
        opacity: 1
      })
      .to('.unterpunkt2', {
        duration: this.durationLong,
        opacity: 1
      })
      .to('.unterpunkt3', {
        duration: this.durationLong,
        opacity: 1
      })
      // Just to make a break
      .to('.ebene4', {
        duration: this.durationLong,
        opacity: 1
      })
      .to('.ebene4', {
        duration: this.durationLong,
        opacity: 0,
        display: 'none',
        scale: 10,
      })
      // Show Ebene 5
      .fromTo('.ebene5', {
        opacity: 0,
        display: 'none',
        scale: 0.2
      }, {
        duration: this.durationLong,
        opacity: 1,
        display: 'flex',
        scale: 1
      })
      .fromTo('.unterpunkt4', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      .to('.unterpunkt4', { //Just for a break
        duration: this.durationLong,
        opacity: 1,
      })
      .fromTo('.unterpunkt5', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      .to('.unterpunkt5', { //Just for a break
        duration: this.durationLong,
        opacity: 1,
      })
      .fromTo('.unterpunkt6', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      .to('.unterpunkt6', { //Just for a break
        duration: this.durationLong,
        opacity: 1,
      })
      .fromTo('.unterpunkt7', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      .to('.unterpunkt7', { //Just for a break
        duration: this.durationLong,
        opacity: 1,
      })
      .fromTo('.unterpunkt8', {
        opacity: 0,
        scale: 0.5
      }, {
        duration: this.durationLong,
        opacity: 1,
        scale: 1
      })
      .to('.unterpunkt8', { //Just for a break
        duration: this.durationLong,
        opacity: 1,
      })
      // Fade out Ebene 5
      .to('.ebene5', {
        duration: this.durationLong,
        opacity: 1,
      })
      .to('.ebene5', {
        duration: this.durationLong,
        opacity: 0,
        display: 'none',
      })


      // Show Ebene 6
      .fromTo('.ebene6', {
        opacity: 0,
        display: 'none',
        scale: 0.9
      }, {
        duration: this.durationLong,
        opacity: 1,
        display: 'flex',
        scale: 1
      })
      // Just to make a break
      .to('.ebene6', {
        duration: this.durationShort,
        opacity: 1
      })
      .to('.einstellung1', {
        duration: this.durationLong,
        opacity: 1
      })
      // Just to make a break
      .to('.einstellung1', {
        duration: this.durationLong,
        opacity: 1
      })
      .to('.einstellung2', {
        duration: this.durationLong,
        opacity: 1
      })
      // Just to make a break
      .to('.einstellung2', {
        duration: this.durationLong,
        opacity: 1
      })
      .to('.einstellung3', {
        duration: this.durationLong,
        opacity: 1
      })
      // Just to make a break
      .to('.einstellung3', {
        duration: this.durationLong,
        opacity: 1
      })
      .call(this.fertig)
      .to('.einstellung3', {
        duration: 1,
        opacity: 1
      })
    ;

    this.timeline = timeline;
    console.log(timeline.duration());
  },
  beforeUnmount() {
    this.timeline.kill();
  },
  methods: {
    stateManage() {
      this.state += 1;
      //console.log(this.state);
    },

    fertig() {
      if (this.done === false) {
        this.$emit('finished', 'fertig');
        //console.log('fertig');
        this.done = true;
      }
    },
    resize() {
      this.$refs.FrameEbene.width = this.$refs.head.clientWidth;//this.$refs.canvasFrame.innerWidth;
      this.$refs.FrameEbene.height = this.$refs.head.clientHeight; //this.$refs.canvasFrame.innerHeight;
    },
    colorize() {

      this.$refs.text1.style.color = this.colors[0];
      this.$refs.text2.style.color = this.colors[1];
      this.$refs.text3.style.color = this.colors[2];
      this.$refs.text4.style.color = this.colors[3];
      this.$refs.text5.style.color = this.colors[4];
      this.$refs.text6.style.color = this.colors[5];
      /*
      let txt = this.$refs.text1.innerHTML.split(" ");
      this.$refs.text1.innerHTML = "";
      for (let i = 0; i < txt.length; i++) {
        this.$refs.text1.innerHTML += '<span style="color: '+this.colors[1]+'">'+txt[i]+'</span>';
      }*/
    },
    colorizeBack() {

      this.$refs.text1.style.color = this.colors[6];
      this.$refs.text2.style.color = this.colors[6];
      this.$refs.text3.style.color = this.colors[6];
      this.$refs.text4.style.color = this.colors[6];
      this.$refs.text5.style.color = this.colors[6];
      this.$refs.text6.style.color = this.colors[6];
      /*
      let txt = this.$refs.text1.innerHTML.split(" ");
      this.$refs.text1.innerHTML = "";
      for (let i = 0; i < txt.length; i++) {
        this.$refs.text1.innerHTML += '<span style="color: '+this.colors[1]+'">'+txt[i]+'</span>';
      }*/
    },

    typeWriter() {
      if (this.i < 50) {
        this.$refs.text1.innerHTML += '   ' + this.NahrungMost5[this.i] + '   ';
        this.$refs.text2.innerHTML += '   ' + this.InteressenMost5[this.i] + '   ';
        this.$refs.text3.innerHTML += '   ' + this.UnterhaltungMost5[this.i] + '   ';
        this.$refs.text4.innerHTML += '   ' + this.ReisenMost5[this.i] + '   ';
        this.$refs.text5.innerHTML += '   ' + this.WissenMost5[this.i] + '   ';
        this.$refs.text6.innerHTML += '   ' + this.ShoppingMost5[this.i] + '   ';
        this.$refs.text11.innerHTML += '   ' + this.NahrungMost5[this.i] + '   ';
        this.$refs.text22.innerHTML += '   ' + this.InteressenMost5[this.i] + '   ';
        this.$refs.text33.innerHTML += '   ' + this.UnterhaltungMost5[this.i] + '   ';
        this.$refs.text44.innerHTML += '   ' + this.ReisenMost5[this.i] + '   ';
        this.$refs.text55.innerHTML += '   ' + this.WissenMost5[this.i] + '   ';
        this.$refs.text66.innerHTML += '   ' + this.ShoppingMost5[this.i] + '   ';
        this.i += 1;
        setTimeout(() => {
          this.typeWriter();
        }, 25);
      }
    },
    lottiPlay() {
      lottie2.setDirection(1);
      lottie2.setSpeed(1);
      lottie2.play();
    },
    lottiPlayBack() {
      lottie2.setDirection(-1);
      lottie2.setSpeed(3);
      lottie2.play();
    },
    goQuelle() {
      let route = this.$router.resolve({ path: '/quellen' });
      window.open(route.href);
    }
  },
};
</script>

<style scoped>
#fensterMagic {
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.head {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  opacity: 0;
  z-index: 10;
  /* border: 2px solid blue;*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}


.ebene1, .ebene2, .ebene3, .ebene31, .ebene11, .ebene4, .ebene5, .ebene6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 98vh;
  width: 90vw;
  z-index: 5;
  /*border: 2px solid red;*/
  overflow: hidden;
  padding: 0;
}


.ebene2 {
  z-index: 12;
  width: 100vw;
}

.ebene3 {
  width: 100%;
  opacity: 1;
}

.ebene31 {
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.row {

}

.col {
  /* border: 2px dotted green;*/
}

.kat, .most5 {
  /* border: 2px dotted green;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

#katInfo, #katInfo2 {
  opacity: 0;
}

.most5 {
  height: revert;
  padding: 0;
}

.noMmargin {
  margin: 0;
}


.bi {
  font-size: 36px;
}

.biKlein {
  font-size: 16px;
}

.grey {
  color: #858585;
}

.fade {
  opacity: 0;
}

/*'#05de8b', '', '',  '', '', '', */
.farbe1 {
  color: #05de8b;
}

.farbe2 {
  color: #07a2e0;
}

.farbe3 {
  color: #5447f1;
}

.farbe4 {
  color: #a1027e;
}

.farbe5 {
  color: #f8143c;
}

.farbe6 {
  color: #ff7a11;
}

.link {
  color: white
}

.link:hover {
  color: white
}

.wordshead {
  text-align: center;
  height: 16.5vh;
  overflow: hidden;
  margin: 0;
  padding: 0;

  white-space: initial;
  text-overflow: ellipsis;
}

.beschreibung {
  opacity: 0;
}

.unterpunkt1, .unterpunkt2, .unterpunkt3, .unterpunkt4, .unterpunkt5, .unterpunkt6, .unterpunkt7, .unterpunkt8 {
  opacity: 0;
  margin: 2vh;
}

.einstellung1, .einstellung2, .einstellung3 {
  opacity: 0;
  text-align: center;
}

.margin {
  padding: 3vh 0;
}

@media screen and (min-width: 800px) {
  .wordshead {
    font-size: 16px;
    height: 11.5vh;
  }

  .head {
    height: 80vh;
  }


  .ebene1, .ebene11 {
    height: 78vh;
    width: 90vw;
  }
}

@media screen and (min-width: 1000px) {
  .wordshead {
    font-size: 20px;
    height: 16.5vh;
  }

  .head {
    height: 100vh;
  }


  .ebene1, .ebene11 {
    height: 98vh;
    width: 70vw;
  }
}

@media screen and (min-width: 1200px) {
  .wordshead {
    font-size: 22px;
  }

  .head {
    height: 100vh;
  }


  .ebene1, .ebene11 {
    height: 98vh;
    width: 70vw;
  }
}

@media screen and (min-width: 1400px) {
  .wordshead {
    font-size: 24pt;
  }
}

@media screen and (min-width: 1600px) {
  .wordshead {
    font-size: 26px;

  }
}

@media screen and (min-width: 2000px) {
  .wordshead {
    font-size: 28px;

  }
}


</style>
